import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  localDev: false,
  tenantId: '67416604-6509-4014-9859-45e709f53d3f',
  clientId: 'e6affeb2-8a4b-452c-aec8-3c29a19fa6f6',
  appId: 'api://397e3055-dc9f-46ce-bcac-e4081037e130/api',
  //backendApiUrl: "http://localhost:5139/api"
  backendApiUrl: "https://roll-mapping-d.dev.dp.schaeffler/api",
  urlToClear: 'https://roll-mapping-d.dev.dp.schaeffler/',
  applicationInsights: {
    applicationInsightsConfig: {
      instrumentationKey: '0dc15e7d-3ac5-4085-8d23-b09728beddad',
      disableCookiesUsage: true,
      autoTrackPageVisitTime: true,
    },
    enableGlobalErrorHandler: true,
    enableNgrxMetaReducer: true,
    ngrxIgnorePattern: ['@ngrx/*', 'Auth\b*'],
  }
};
