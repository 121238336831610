import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { login } from '@schaeffler/azure-auth';

import { getProfileImage, getUsername } from '@schaeffler/azure-auth';

@Component({
  selector: 'na-roll-mapping-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Roll Mapping';

  public username$!: Observable<string>;
  public profileImage$!: Observable<string>;

  public constructor(private readonly store: Store) {}

  public ngOnInit(): void {
    this.username$ = this.store.select(getUsername);
    this.profileImage$ = this.store.select(getProfileImage);

    if (this.username$ == undefined || this.username$ == null) {
      this.store.dispatch(login());
    }

  }

  triggerEscapeKey(): void {
    const event = new KeyboardEvent("keyup",{
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      bubbles: true
      });
    document.dispatchEvent(event);
  }
}

