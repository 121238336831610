<schaeffler-app-shell
  [appTitle]="title"
  [hasSidebarLeft]="true"
  [userName]="username$ | ngrxPush"
  [userImageUrl]="profileImage$ | ngrxPush"
  [hasFooter]="true"
>
  <ng-container sidenavBody>
    <div class="flex flex-col mt-4 p-4 items-center justify-start w-full">
      <span class="text-medium-emphasis text-h4-mobile font-semibold w-full"
        >Roll Mapping</span
      >
      <div class="mt-8 flex flex-col justify-start w-full">
        <span
          class="text-medium-emphasis text-h6-mobile inline-block mb-4 w-full font-semibold"
          >General</span
        >
        <ul>
          <li>
            <a
              routerLink="/dashboard"
              class="text-medium-emphasis text-h5-mobile inline-block w-full flex flex-row items-center justify-start mb-1"
              (click)="triggerEscapeKey()"
              >
              <div class="flex flex-row items-center">
                <mat-icon fontSet="material-symbols-outlined" class="text-medium-emphasis text-h6-mobile mr-2"
                  >home</mat-icon
                >
                <span class="text-medium-emphasis text-h6-mobile">Dashboard</span>
              </div>
            </a>
          </li>
          <li>
            <a
              routerLink="/paper-machines"
              class="text-medium-emphasis text-h6-mobile inline-block w-full cursor-pointer"
              (click)="triggerEscapeKey()"
              >
              <div class="flex flex-row items-center">
                <mat-icon fontSet="material-symbols-outlined" class="text-medium-emphasis text-h6-mobile mr-2"
                  >conveyor_belt</mat-icon
                >
                <span class="text-medium-emphasis text-h6-mobile">Paper Machines</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-container mainContent>
    <div class="fullscreen-bg h-full w-full">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</schaeffler-app-shell>
